@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  * {
    font-family: 'Poppins', serif;
  }

  body {
    font-family: 'Poppins', serif;
    overflow-x: hidden;
  }

  li {
    @apply p-10 text-white 
  }

  button {
    @apply text-white border bg-red-800 border-red-800 cursor-pointer
    hover:bg-transparent hover:text-red-800 rounded-md
  }

  button2 {
    @apply text-white border bg-transparent border-white cursor-pointer
    hover:bg-red-800  hover:border-red-800
  }


}